function searchEngine(query, record) { return record;}

export default function initAutocompleteBooks(url, selector, selectionFn, searchBooks, searchOlWorks) {
  const config = {
    searchEngine: searchEngine,
    placeHolder: "Search for books...",
    data: {
      src: async (query) => {
        try {

          let filteredData = [];
          
          // Create a RegExp for case-insensitive matching of the query
          const queryRegex = new RegExp(query, 'i');

          if (searchBooks) {
            // Fetch Data from external Source
            const source = await fetch(`${url}?query=${query}`);
            let data = await source.json();
            console.log("data: ", data)

            filteredData = data.filter(item => {
              // Check if item.title matches the query (case-insensitive)
              return queryRegex.test(item.title);
            });
          }


          if (filteredData.length === 0 && searchOlWorks) {
            const source = await fetch(`${url}?query=${query}&index=ol_works`);
            let data = await source.json();
            console.log("data: ", data)

            filteredData = data.filter(item => {
              // Check if item.title matches the query (case-insensitive)
              return queryRegex.test(item.title);
            });
          }

          return filteredData;
        } catch (error) {
          console.error("error: ", error);
          return error;
        }
      },
      // Data 'Object' key to be searched
      keys: ["display_text"],
      filter: (list) => {
        console.log("filter list: ", list)
        const uniqueMatches = {};

        // Filter the list to only include unique matches.
        const filteredList = list.filter(item => {
          // Convert the match value to lowercase (or uppercase) for case-insensitive comparison.
          const matchValue = item.match.toLowerCase();
        
          // Check if the lowercase match value of the current item is not already in uniqueMatches.
          if (!uniqueMatches[matchValue]) {
            // If it's not, add it to uniqueMatches and keep this item in the filtered list.
            uniqueMatches[matchValue] = true;
            return true;
          }
          // If it's already in uniqueMatches, filter out this item.
          return false;
        });        

        return filteredList;
      }
    },
    resultsList: {
      tag: "ul",
      class: "list-group autocomplete-book-results",
    },
    resultItem: {
      tag: "li",
      class: "list-group-item list-group-item-secondary autocomplete-results-item small-text"
    },
    selector: selector,
    events: {
      input: {
          selection: selectionFn
      }
    }
  };
  return config;
}

